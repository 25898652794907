// components
import Headroom from 'react-headroom'; // スクロールでヘッダーを隠してくれる
import { Image } from '../../atoms/Image';

// other
import React, { useState } from 'react';
import styled from 'styled-components';
import { borderColor, breakpoint } from '../../theme';
import useMedia from '../../../customHooks/userMedia';

const Header = () => {
  const isMobile = useMedia('(max-width: 767px)');

  return (
    <StyledHeader>
      <Headroom
        style={{
          borderBottom: '1px solid ' + borderColor,
          background: 'rgb(250, 182, 174)',
        }}
      >
        <Content>
          <Image
            src={
              'https://static.croccha.com/company/ec-movie/ec-movie-parlor-logo-03.svg'
            }
            alt='動画パーラー'
            width={isMobile ? '147px' : '280px'}
          />

          <ContactBox>
            <ContactBoxDesc>
              まずはお気軽にお問い合わせください！
            </ContactBoxDesc>
            <ContactBoxDescSp>お気軽にお問い合わせください</ContactBoxDescSp>
            <ContactBoxTel>
              <span>TEL</span> <a href='tel:0677770945'>06-7777-0945</a>
            </ContactBoxTel>
            <ContactBoxTime>
              <span>受付</span>平日10:00~17:00
            </ContactBoxTime>
          </ContactBox>
        </Content>
      </Headroom>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  /* headroom分がコンテンツ分の高さを取ってくれない */
  height: 120px;

  @media ${breakpoint.sp} {
    height: 88px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0.5rem 70px;
  align-items: center;

  @media ${breakpoint.sp} {
    padding: 10px 16px;
  }
`;

const ContactBox = styled.div`
  max-width: 60%;

  @media ${breakpoint.sp} {
    margin-left: 16px;
  }
`;

const ContactBoxDesc = styled.p`
  font-size: 16px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    display: none;
  }
`;

const ContactBoxDescSp = styled.p`
  font-size: 12px;
  font-weight: bold;

  @media ${breakpoint.tab} {
    display: none;
  }
`;

const ContactBoxTel = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media ${breakpoint.sp} {
    font-size: 16px;
  }

  & > span {
    font-size: 16px;
    background: #ff8574;
    padding: 4px 8px;
    color: #fff;
    border-radius: 4px;

    @media ${breakpoint.sp} {
      font-size: 11px;
    }
  }
`;

const ContactBoxTime = styled.p`
  margin-top: 4px;
  font-size: 16px;

  @media ${breakpoint.sp} {
    font-size: 13px;
  }

  & > span {
    font-size: 16px;
    font-weight: bold;
    background: #ff8574;

    margin-right: 8px;
    padding: 4px 8px;
    color: #fff;
    border-radius: 4px;

    @media ${breakpoint.sp} {
      font-size: 11px;
    }
  }
`;
