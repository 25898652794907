// other
import React from 'react';
import styled from 'styled-components';

import { fontSize, breakpoint } from '../../theme';
import { SquareLogo } from '../../../utils';
import LazyLoadImage from '../../atoms/LazyLoadImage';
import Anchor from '../../atoms/Anchor';

const Footer = () => (
  <FooterContainer>
    <FooterBottomWrap>
      <FooterBottom>
        {/* 会社情報 */}
        <CompanyInfo>
          <Anchor url='/'>
            <LogoWrap>
              <Logo src={SquareLogo} width='100px' alt='tryangle' />
            </LogoWrap>
          </Anchor>
          <div>
            <div>本店</div>
            <div>大阪府東大阪市桜町2-3 日興桜町ビル4F</div>
            <div>
              <a href='tel:0677770945'>Tel : 06-7777-0945</a>
            </div>
            <AddressBottom>
              <a href='m&#97;i&#108;t&#111;:inf&#111;&#64;&#100;o&#117;&#103;&#97;-&#112;&#97;rl&#111;&#114;&#46;jp'>
                Mail:
                inf&#111;&#64;&#100;o&#117;&#103;&#97;-&#112;&#97;rl&#111;&#114;&#46;jp
              </a>
            </AddressBottom>
          </div>
        </CompanyInfo>
      </FooterBottom>
    </FooterBottomWrap>

    <Copyright>
      Copyright © 2021 tryangle Co., Ltd. All Rights Reserved.
    </Copyright>
  </FooterContainer>
);

export default Footer;

const FooterContainer = styled.footer`
  background: #1f1f1e;
  color: #fff;
  margin-top: auto;

  > div {
    @media ${breakpoint.sp} {
      padding: 40px 1rem;
    }
    @media ${breakpoint.tab} {
      margin: 0 auto;
      max-width: 1024px;
      padding: 1rem 70px;
    }
  }
`;

const FooterBottomWrap = styled.div`
  position: relative;
  min-height: 300px;

  @media ${breakpoint.tab} {
    min-height: 150px;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompanyInfo = styled.div`
  @media ${breakpoint.sp} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  @media ${breakpoint.tab} {
    display: flex;
  }
  font-size: 13px;
  a {
    color: #fff;
  }
  margin-top: 20px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const LogoWrap = styled.div`
  @media ${breakpoint.sp} {
    margin-bottom: 2rem;
    margin-right: auto;
  }
`;

const Logo = styled(LazyLoadImage)`
  @media ${breakpoint.tab} {
    margin-right: 2rem;
  }
`;

/* コピーライト */
const Copyright = styled.div`
  max-width: 1024px;
  margin: auto;
  padding: 0 70px 1.5rem;
  font-size: ${fontSize.small};
  text-align: center;
`;

const AddressBottom = styled.div`
  margin-bottom: 1rem;
`;
