import * as React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

type AnchorProps = {
  url: string;
  asHref?: string;
  className?: string;
};

const Anchor: React.FC<AnchorProps> = ({ url, children, className = '' }) => {
  const isExternal = /^https?:\/\//.test(url);

  return (
    <>
      {isExternal ? (
        <A
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          className={className}
        >
          {children}
        </A>
      ) : (
          <Link to={url} className={className}>
            <Div>{children}</Div>
          </Link>
        )}
    </>
  );
};

export default Anchor;

type DisabledAnchorProps = {
  disable: boolean;
  onClick: () => void;
} & AnchorProps;

/**
 * Anchorにdisableを付けたラッパー
 * disable==trueのときに発火させるonClickを指定する
 */
export const DisabledAnchor: React.FC<DisabledAnchorProps> = ({
  children,
  disable,
  ...props
}) => {
  if (disable) {
    return <div onClick={props.onClick}>{children}</div>;
  }
  return <Anchor {...props}>{children}</Anchor>;
};

const A = styled.a`
  display: inline-block;
`;

const Div = styled.div`
  display: inline-block;
`;
