import * as React from 'react';
import styled, { css } from 'styled-components';
import { Image } from '../../../components/atoms/Image';

interface LazyLoadImageProps {
  src: string | ArrayBuffer;
  alt?: string;
  imgId?: string;
  style?: string;
  type?: string;
  width?: string;
  height?: string;
  maxHeight?: string;
  mode?: string;
  isIcon?: boolean;
  className?: string;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  height = '',
  width = '',
  ...props
}) => (
  <Wrap width={width} height={height}>
    <Image
      {...props}
      height={height}
      width={width}
      isBackground={false}
      style={props.className || ''}
    />
  </Wrap>
);

export default LazyLoadImage;

const Wrap: any = styled.div`
  ${({ height, width }: any) => css`
    min-width: ${width};
    min-height: ${height};
  `};
`;
