import * as React from 'react';

export interface ContainerProps {
  presenter: (presenterProps: {}) => any;
}

export const containPresenter = (Container: any, Presenter: any) => (
  props: any
) => (
  <Container
    presenter={(presenterProps: any) => <Presenter {...presenterProps} />}
    {...props}
  />
);
