export const LOCAL_API = 'localHost';
export const HOST_STAGING_API = 'stagingAPIGateway';
export const HOST_PROD_API = 'prodAPIGateway';
export const HOST_STAGING_ALB_API = 'stagingALB';
export const HOST_PROD_ALB_API = 'prodALB';

// 写真のアップロードに使用中
export const ALB_LOCAL = 'http://localhost:3000';
export const ALB_STAGING = 'https://dev.alb.app.croccha.com:3000';
export const ALB_PROD = 'https://alb.app.croccha.com:3000';

export const HTTP_PROTOCOL = 'http://';
export const HTTPS_PROTOCOL = 'https://';
export const ORIGIN = [
  'http://localhost:3000/',
  'http://localhost:3001/',
  'https://dev.web.croccha.com/',
  'https://web.croccha.com/',
];

const ConfigProd = {
  NCMB: {
    clientKey:
      'ae084afd2d015c76d1b2bb9d5021f3c6ca2e1b53a237532b2e4b7e1e69771b4d',
    secretKey:
      '35192af0565e4b0bf71e7ab4a74e46c6e517f52c18e39fba9db8b02eca06446d',
    FCMSenderId: '481310049067',
  },
  GoogleAnalytics: {
    id: 'UA-98982894-4',
  },
  Slack: {
    url: 'https://slack.com/api/chat.postMessage',
    token:
      'xoxp-213412033284-213339416083-394522030050-c140f2632fbe916012ebfb38bb461222',
  },
};

const ConfigStaging = {
  NCMB: {
    // 変更する必要あり
    clientKey:
      'fb3b0d3db796eabeef62bcdff473ba136e24a398e483ca4e824b2d13f0e1208a',
    secretKey:
      'c4455b160b55652609d8788ddb8d85fd7e6e47c89f4dc872a44f175be70613c7',
    FCMSenderId: '481310049067',
  },
  GoogleAnalytics: {
    id: 'UA-98982894-12',
  },
  Slack: {
    url: 'https://slack.com/api/chat.postMessage',
    token:
      'xoxp-213412033284-213339416083-394522030050-c140f2632fbe916012ebfb38bb461222',
  },
};

/* APサーバ(node.js)のホスト  */
/* ローカル  */
// export const CROCCHA_API = {
//   AP_HOST: LOCAL_API,
//   AP_LAMBDA_HOST: LOCAL_API,
//   ALB_HOST: ALB_LOCAL,
//   CONFIG: ConfigStaging,
//   S3_HOST: 'staging',
//   STATIC_HOST: 'https://static.croccha.com',
//   STATIC_HOST_OLD: 'https://static.mano-handmade.com',
//   SHOP: 'https://dev.shop.croccha.com/tryangle/authentication/',
//   SHOP_TOP: 'https://dev.shop.croccha.com',
//   SHOP_SEARCH:
//     'https://dev.shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
//   MASE_HOST: 'maseAPI',
//   ASSETS: 'https://static.croccha.com/company/',
//   SERVICEWORKER: 'http://localhost:5000/service-worker.js',
//   GA_TRACKING_CODE: '',
//   AMPLITUDE_API_KEY: 'stgApiKey',
//   SELF_DOMAIN: 'localhost:8000'
// };

// /* 開発環境  */
// export const CROCCHA_API = {
//   AP_HOST: HOST_STAGING_ALB_API,
//   AP_LAMBDA_HOST: HOST_STAGING_API,
//   ALB_HOST: ALB_STAGING,
//   CONFIG: ConfigStaging,
//   S3_HOST: 'production',
//   STATIC_HOST: 'https://static.croccha.com',
//   STATIC_HOST_OLD: 'https://static.mano-handmade.com',
//   SHOP: 'https://shop.croccha.com/tryangle/authentication/',
//   SHOP_TOP: 'https://dev.shop.croccha.com',
//   SHOP_SEARCH:
//     'https://dev.shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
//   MASE_HOST: 'maseAPI',
//   ASSETS: 'https://static.croccha.com/company/',
//   SERVICEWORKER: 'https://dev.web.croccha.com/service-worker.js',
//   GA_TRACKING_CODE: 'UA-98982894-10',
//   AMPLITUDE_API_KEY: 'stgApiKey',
//   SELF_DOMAIN: 'http://stg-company-hp.s3-website-ap-northeast-1.amazonaws.com'
// };

/* 本番環境  */
export const CROCCHA_API = {
  AP_HOST: HOST_PROD_ALB_API,
  AP_LAMBDA_HOST: HOST_PROD_API,
  ALB_HOST: ALB_PROD,
  CONFIG: ConfigProd,
  S3_HOST: 'production',
  STATIC_HOST: 'https://static.croccha.com',
  STATIC_HOST_OLD: 'https://static.mano-handmade.com',
  SHOP: 'https://shop.croccha.com/tryangle/authentication/',
  SHOP_TOP: 'https://shop.croccha.com',
  SHOP_SEARCH:
    'https://shop.croccha.com/tryangle/authentication/stp/product/search/typeA1/',
  MASE_HOST: 'maseAPI',
  ASSETS: 'https://static.croccha.com/company/',
  SERVICEWORKER: 'https://web.croccha.com/service-worker.js',
  GA_TRACKING_CODE: 'UA-98982894-11',
  AMPLITUDE_API_KEY: 'prodApiKey',
  SELF_DOMAIN: 'https://try.croccha.com',
};

/**
 * デバッグ用の変数
 */
// export const skipWelcome = true;
// export const initLogin = true;
export const skipWelcome = false;
export const initLogin = false;
export const purgeOnInitialize = false;
export const DeleteLog = false;
export const checkVoter = false;
export const username = 'test21';
export const password = '123456789o';
// export const username = 'ttest3142';
// export const password = '123456789o';
export const isProduction = CROCCHA_API.AP_HOST === HOST_PROD_ALB_API;

/**
 * 管理者権限
 * 管理者権限を持つIDを60以下全てにする
 */
export const adminId = 60;

/**
 * webappとRNのプロジェクトの差分を吸収するために作成
 * まだ未使用
 */
export const isWebapp = false;
export const isWebappNext = true;
export const loginUserUID = 'self-user'; // uuid.v4();
export const timelinePrefix = 't-';
export const userLoginKey = 'Q7yaJFtK';
export const mostOldDay = '2018-01-01'; // サービス開始前の一番古い日付
export const REQUEST_TIMEOUT = 10000; // タイムアウトは10秒にする
export const HIDE_TOAST = 3000; // トーストの非表示までの時間

/**
 * 画像のURLはここで管理する
 */
export const MainPhoto1 = `${CROCCHA_API.ASSETS}png/app-ss01.png`;
export const MainPhoto2 = `${CROCCHA_API.ASSETS}png/app-ss02.png`;
export const AppBnr01 = `${CROCCHA_API.ASSETS}app_store_bnr.svg`;
export const AppBnr02 = `${CROCCHA_API.ASSETS}google-play-badge.png`;
export const HeartIcon = `${CROCCHA_API.ASSETS}icon-heart-o.svg`;
export const HeartIcon2 = `${CROCCHA_API.ASSETS}icon-heart.svg`;
export const itemNoImage = `https://static.croccha.com/webapp/banners/jpg/item-no-image.jpg`;
export const userIcon = `${CROCCHA_API.ASSETS}mypage-thumbnail.svg`;
export const StarIcon = `${CROCCHA_API.ASSETS}icon-star.svg`;
export const StarIcon2 = `${CROCCHA_API.ASSETS}icon-star-o.svg`;
export const BgImg = `${CROCCHA_API.ASSETS}jpg/bg.jpg`;
export const LinkIcon = `${CROCCHA_API.ASSETS}icon-ex-link.svg`;
export const Link01 = `${CROCCHA_API.ASSETS}jpg/banner_croccha_shop.jpg`;
export const Link02 = `${CROCCHA_API.ASSETS}jpg/kit_sell.jpg`;
export const Link03 = `${CROCCHA_API.ASSETS}jpg/kit_sell_closed.jpg`;
export const AppMessage01 = `${CROCCHA_API.ASSETS}png/app-ad-message01.png`;
export const AppMessage02 = `${CROCCHA_API.ASSETS}png/app-ad-message02.png`;
export const AppMessage03 = `${CROCCHA_API.ASSETS}png/app-ad-message03.png`;
export const AppMonitor01 = `${CROCCHA_API.ASSETS}png/app-monitor-image01.png`;
export const AppMonitor02 = `${CROCCHA_API.ASSETS}png/app-monitor-image02.png`;
export const DateIcon = `${CROCCHA_API.ASSETS}icon-clock.svg`;
export const PostIcon = `${CROCCHA_API.ASSETS}icon-post.svg`;
export const CrocchShopLogo = `${CROCCHA_API.ASSETS}croccha_shop_logo_w.svg`;
export const logo = `${CROCCHA_API.ASSETS}croccha_logo.png`;
export const FollowIcon1 = `${CROCCHA_API.ASSETS}icon-follow-w.svg`;
export const FollowIcon2 = `${CROCCHA_API.ASSETS}icon-follow.svg`;
export const FollowIcon3 = `${CROCCHA_API.ASSETS}icon-follow-o.svg`;
export const SearchIcon = `${CROCCHA_API.ASSETS}icon-search.svg`;
export const SearchIcon2 = `${CROCCHA_API.ASSETS}icon-search-p.svg`;
export const CartIcon = `${CROCCHA_API.ASSETS}icon-cart.svg`;
export const ShopBannerBg = `${CROCCHA_API.ASSETS}jpg/croccha_shop_bg.jpg`;
export const CrocchaShopLogo = `${CROCCHA_API.ASSETS}croccha_shop_logo_w.svg`;
export const top02 = `${CROCCHA_API.ASSETS}top02.svg`;
export const top03 = `${CROCCHA_API.ASSETS}top03.svg`;
export const top04 = `${CROCCHA_API.ASSETS}top04.svg`;
export const TagIcon = `${CROCCHA_API.ASSETS}pentagon.svg`;
export const PouchIcon = `${CROCCHA_API.ASSETS}icon-pouch-p.svg`;
export const userPic = `${CROCCHA_API.ASSETS}jpg/userPic.jpg`;
export const top05 = `${CROCCHA_API.ASSETS}png/top05.png`;
export const top06 = `${CROCCHA_API.ASSETS}png/top06.png`;
export const top07 = `${CROCCHA_API.ASSETS}png/top07.png`;
export const MypageIcon = `${CROCCHA_API.ASSETS}icon-mypage.svg`;
// export const FavIcon = `${CROCCHA_API.ASSETS}icon-fav.svg`;
export const FavIcon2 = `${CROCCHA_API.ASSETS}icon-fav-02.svg`;
export const CommentIcon = `${CROCCHA_API.ASSETS}icon-comment.svg`;
export const MailIcon = `${CROCCHA_API.ASSETS}icon-mail.svg`;
export const SettingIcon = `${CROCCHA_API.ASSETS}icon-setting.svg`;
export const LogoutIcon = `${CROCCHA_API.ASSETS}icon-logout.svg`;
export const SearchLink01 = `${CROCCHA_API.ASSETS}jpg/search-link01.jpg`;
export const SearchLink02 = `${CROCCHA_API.ASSETS}jpg/search-link02.jpg`;
export const SearchLink03 = `${CROCCHA_API.ASSETS}jpg/search-link03.jpg`;
export const ContestBanner = `${CROCCHA_API.ASSETS}jpg/contest-banner.jpg`;
export const TutorialFunrepo = `${CROCCHA_API.STATIC_HOST}/webapp/contest/tutorial/contest_tutorial.png`;
export const Contest01Banner = `${CROCCHA_API.STATIC_HOST}/events/shop_fanrepo.jpg`;
export const AuthorizedShopIcon = `${CROCCHA_API.ASSETS}icon-official-shop.svg`;
export const AuthorizedUserIcon = `${CROCCHA_API.ASSETS}icon-official-user.svg`;
export const WebSiteIcon = `${CROCCHA_API.ASSETS}icon-pc.svg`;
export const YoutubeIcon = `${CROCCHA_API.ASSETS}icon-youtube.png`;

// head / footer
// sp
export const SpHead = `${CROCCHA_API.ASSETS}png/sp/head/sp_head.png`;
export const SpFooter = `${CROCCHA_API.ASSETS}png/sp/footer/sp_footer.png`;

// pc
export const PcBgImg = `${CROCCHA_API.ASSETS}png/pc/pc_bg.png`;
export const PcHead = `${CROCCHA_API.ASSETS}png/pc/head/pc_head.png`;
export const PcFooterLogo = `${CROCCHA_API.ASSETS}png/pc/footer/logo.png`;
export const PcFooterMockup = `${CROCCHA_API.ASSETS}png/pc/footer/mockup.png`;
export const PcFooterQrApple = `${CROCCHA_API.ASSETS}png/pc/footer/qr_appstore.png`;
export const PcFooterQrGoogle = `${CROCCHA_API.ASSETS}png/pc/footer/qr_googleplay.png`;

export const normalTypeImage = `/images/login-modal-01.png`;
export const contestTypeImage = `/images/login-modal-02.png`;
export const couponTypeImage = `/images/login-modal-03.png`;

// 会社HP
// favicon
export const FavIcon = `${CROCCHA_API.ASSETS}favicon.ico`;
// touch icon
export const LogoIcon = `${CROCCHA_API.ASSETS}/logo/logo-icon.jpg`;
// lgoo
export const InlineLogo = `${CROCCHA_API.ASSETS}logo/logo.svg`;
export const SquareLogo = `${CROCCHA_API.ASSETS}logo/tr_logo.svg`;

// 各ページのヘッダ画像
export const PhilosophyPc = `${CROCCHA_API.ASSETS}page/philosophy_pc_.png?w=1100`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const PhilosophySp = `${CROCCHA_API.ASSETS}page/philosophy_sp_.png`;
export const CompanyPc = `${CROCCHA_API.ASSETS}page/company_pc_.png?w=1200`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const CompanySp = `${CROCCHA_API.ASSETS}page/company_sp_.png`;
export const NewsPc = `${CROCCHA_API.ASSETS}page/news_pc_.png?w=1200`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const NewsSp = `${CROCCHA_API.ASSETS}page/news_sp_.png`;

// hero
export const HeroImg = `${CROCCHA_API.ASSETS}hero.jpg`;
// アイコン
export const MenuIcon = `${CROCCHA_API.ASSETS}icon/icon-menu.svg`;
export const InstagramIcon = `${CROCCHA_API.ASSETS}icon/icon-instagram.svg`;
export const FacebookIcon = `${CROCCHA_API.ASSETS}icon/icon-facebook-square.svg`;
export const TwitterIcon = `${CROCCHA_API.ASSETS}icon/icon-twitter-square.svg`;
export const SymbolIcon = `${CROCCHA_API.ASSETS}icon/Symbol.svg`;

// product紹介
export const CrocchaLogo = `${CROCCHA_API.ASSETS}product/croccha_logo_w.png`;
export const Mockup = `${CROCCHA_API.ASSETS}product/mockup_fix_.png?w=500`; //note: 画像が大きすぎて表示できないのでリサイズしている
export const ServiceBg = `${CROCCHA_API.ASSETS}product/service_bg.png`;
export const BgProduct = `${CROCCHA_API.ASSETS}product/bg_product.png`;
export const BgProductSp = `${CROCCHA_API.ASSETS}product/service_Bg_SP.png`;
export const AppleStore = `${CROCCHA_API.ASSETS}product/app_store.png`;
export const GooglePlay = `${CROCCHA_API.ASSETS}product/google_play.png`;

// メンバー
export const Member01 = `${CROCCHA_API.ASSETS}member/member01_.png`;
export const Member02 = `${CROCCHA_API.ASSETS}member/member02.png`;

// 動画制作LP
export const Specialized = `https://static.croccha.com/company/ec-movie/ec_specialized_02.png?w=600`;
export const PriceBtn = `https://static.croccha.com/company/ec-movie/button-price-01.svg?w=600`;
export const RequestBtn = `https://static.croccha.com/company/ec-movie/button-datarequest-02.png?w=600`;
export const ProblemMsg = `https://static.croccha.com/company/ec-movie/problem_message_01.png?w=600`;
export const ProblemImg = `https://static.croccha.com/company/ec-movie/bother_human_02.png?w=600`;
export const EcMovieParlorLogo = `https://static.croccha.com/company/ec-movie/ec-movie-parlor-logo-03.svg`;
export const BagImage = `https://static.croccha.com/company/ec-movie/bag_image_01.jpg?w=300`;
export const BagImageSlideShow = `https://static.croccha.com/company/ec-movie/bag_image_slideshow_01.jpg?w=300`;
export const PosterSneaker = `https://static.croccha.com/company/ec-movie/image_Black_Sneaker_AD-0218_02.png?w=600`;
export const PosterEmbossedHeater = `https://static.croccha.com/company/ec-movie/image_howtouse_Embossed_heater_02.png?w=600`;
export const PosterDriedFruits = `https://static.croccha.com/company/ec-movie/image_dried_fruit_02.png?w=600`;
export const PosterYellowBag = `https://static.croccha.com/company/ec-movie/image_yellow_wallet_sholder_bag_02.png?w=600`;

export const TopMP4 = `https://static.croccha.com/company/ec-movie/ecmovieparlor-top_02.mp4`;
export const PosterSneakerMP4 = `https://static.croccha.com/company/ec-movie/Black_Sneaker_AD-0218_02.mp4`;
export const PosterEmbossedHeaterMP4 = `https://static.croccha.com/company/ec-movie/howtouse_Embossed_heater_02.mp4`;
export const PosterDriedFruitsMP4 = `https://static.croccha.com/company/ec-movie/dried_fruit_02.mp4`;
export const PosterYellowBagMP4 = `https://static.croccha.com/company/ec-movie/yellow_wallet_sholder_bag_02.mp4`;

// 開発用に後で使用する
// export const avatarImg = `https://dev.static.croccha.com/tutorials/Avatar.jpg`;
// export const TutorialFunrepo = `${CROCCHA_API.STATIC_HOST}/webapp/contest/tutorial/contest_tutorial.png`;

export const notifyRenewal = `https://shop.croccha.com/feature/20190913_renewal_app`;
export const TimelineLimit = 3; // 今タイムラインは4件ずつ取得しているので、左記以下ならロードしない
export const LoadingLimit = 3000; // ローディングを表示する期間(ms)
export const ModalBackdropPressLimit = 10000; // Modalを表示して、最低限消せない時間(ms)
export const SearchHistoryLimit = 10; // 履歴を何軒まで保持するのか
export const SearchCommentThllotle = 500; // コメントにIDなどを検索した時のthrollte
export const ModalToAlertDelay = 1500;
export const ModalToAlertDelayLong = 1500;
export const THROTTLE = 1000;
export const GET_THROTTLE = 1000;
export const CONTEST_TOP_NUM = 6; // トップページでコンテストを取得する件数
export const POST_THROTTLE = 1500;
export const RAKUTEN_GET_THROTTLE = 3000;
export const POSTING_TIMEOUT = 20000; // 投稿時のタイムアウト設定
export const POST_PIC_NUM = 12; // 一度に投稿を取得する件数
export const TOP_NEWUSER_POST_PIC_NUM = 4; // TOPページの新規作成ユーザの投稿を取得する件数
export const TOP_FANREPO_POST_PIC_NUM = 4; // TOPページのファンれぽ投稿一覧の取得する件数
export const NEW_USER_POST_PIC_NUM = 4; // 一度に投稿を取得する件数
export const GENRE_CONTEST_NUM = 3; // 一度にジャンルに紐づいたコンテストを取得する件数
export const GENRE_MONITOR_NUM = 3; // 一度にジャンルに紐づいたモニターを取得する件数
export const GET_NOTIFY_NUM = 12; // 一度にお知らせを取得する件数
export const SEARCH_POSTS_IN_SAME_CATEGORY_NUM = 999; // 一度にアイテム関連の投稿を取得する件数
export const SEARCH_ITEM_NUM = 10; // 一度にアイテムを取得する件数
export const SEARCH_KIT_NUM = 4; // 一度にキットを取得する件数
export const SEARCH_COMMENT_NUM = 12; // 一度にコメントを取得する件数
export const SEARCH_POUCH_NUM = 16; // 一度にポーチを取得する件数
export const SEARCH_FOLLOW_USER_NUM = 16; // 一度にフォローユーザーを取得する件数
export const SEARCH_RECIPE_VIDEOS_NUM = 24; // 一度にレシピ動画を取得する件数
export const RELATED_POSTPICS_NUM = 12; // 関連する投稿
export const SEARCH_ALL_NUM = 999; // 全件取得する件数
export const ALL_COMMENTS_NUM = 999; // 全件取得する件数

// キーワードから探す
export const keywords = ['レジン', 'フラワー', 'スイーツデコ', '粘土'];

// 投稿編集を表示するユーザー
// user_nameを指定する
export const showPostEditUser = [
  'partsclub',
  'craft_town',
  'shugale',
  'my_flower_life',
  'yoshida_shoji',
  'sakurahorikiri',
  'cropparty',
  'orange_mikaniro', // アプリが使えなくなってしまったので
  'fuji4666',
  'test21',
  'akepon64',
  'coeur_du_bijoux',
  'asunarosyugeiten',
  'popotan', // 福田さんのアカウント
  'croccha',
];

// shopを増やせるようにしておく
export const shopRoutes = [
  { key: 'crocchaAdded', title: 'すべて' },
  { key: 'rakuten', title: '楽天' },
  { key: 'shugale', title: 'シュゲール' },
  { key: 'crocchaShop', title: 'croccha shop' },
];
export const crocchaAddedIndex = 0;
export const rakutenIndex = 1;
export const shugaleIndex = 2;
export const crocchaShopIndex = 3;

export const amazonUrlIndex = 'amazon';
export const shugaleUrlIndex = 'shugale';
export const myFlowerLifeUrlIndex = 'myflowerlife';
export const croppartyUrlIndex = 'crop-party';
export const rakutenUrlIndex = 'rakuten';
export const crocchaShopUrlIndex = 'crocchaShop';

export const yoshidashojiUrlIndex = 'yoshida-shoji';
export const partsclubUrlIndex = 'partsclub';
export const sakurahorikiriUrlIndex = 'sakurahorikiri';
export const asunarosyugeitenUrlIndex = 'asunarosyugeiten';
export const coeurUrlIndex = 'https://store.shopping.yahoo.co.jp/coeur/';

export const otherShopUrlIndex = 'other';

// 検索時に、表示対象から外す(usernameを指定、正規表現あり)
// export const NOT_SHOW_USERS = ['admin', 'guest', '.*test.*'];
// TODO:あとで戻す
export const NOT_SHOW_USERS = ['admin', 'guest'];

export const dispnumberList = [10, 20, 30];
export const orderbyList = [
  'popular',
  'created_at_oldest',
  'created_at_newest',
];

// ここからエラーメッセージ
export const ERR_SAVE_IMAGE = '写真の保存に失敗しました';

export function getdoubleDigestNumer(str: string | number) {
  return `0${str}`.slice(-2);
}

// = ====================================================
// <img>要素 → Base64形式の文字列に変換
//   img       : HTMLImageElement
//   mime_type : string "image/png", "image/jpeg" など
//   return "data:image/jpeg;base64,XXXXXX..." みたいな文字列
//   usage: var b64 = dataURItoBlob(img, "image/jpeg");
// = ====================================================
export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  return new Blob([ia], { type: mimeString });
};
