export default {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  primary: '#FA6D63',
  primaryLight: '#FFF5F4',
  primaryDark: '#fce2e2',

  secondary: '#ff4081',
  secondaryLight: '#ff79b0',
  secondaryDark: '#c60055'
};
