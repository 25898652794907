import { css, FlattenSimpleInterpolation } from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const mainColor = '#FA6D63';
export const mainOpacityColor = 'rgba(250, 109, 99, 0.4)';
export const secondaryColor = '#fff';
export const accentColor = '#82B4F0';
export const bgColor = '#FFF5F4';
export const bgOpacityColor = 'rgba(245, 245, 245, 0.8)';
export const borderColor = '#DDDDDD';
export const textColor = '#212121';
export const subTextColor = '#7c7c7c';
export const placeholderColor = '#a2a1a1';
export const socialIconBgColor = ' #898f9c';
export const hintIconColor = '#C4C4C4';
export const boxShadow = '0px 0px 6px rgba(124, 124, 124, 0.4)';
export const errorColor = 'red'; // TODO:

// 両サイドのpadding
export const sidePadding = '1rem';

export const fontSize = {
  small: '0.75rem',
  default: '0.875rem',
  large: '1rem'
};

export const lineHeight = {
  small: '16px',
  default: '18px',
  large: '20px'
};

export const headingFontSize = {
  small: '0.875rem',
  medium: '1rem',
  large: '1.125rem',
  //
  xLarge: '1.375rem',
  // 51px
  xxLarge: '3.1875rem',
  // 54px
  xxxLarge: '3.375rem'
};

export const breakpoint = {
  sp: 'screen and (max-width: 767px)', // スマホサイズ
  tab: 'screen and (min-width: 768px)', // タブレット以上
  tabOnly: 'screen and (min-width: 768px) and (max-width: 959px)' // タブレットのみ
};

export type ButtonColor = 'primary' | 'secondary' | 'cancel';

export const primaryButtonStyle = css`
  display: inline-block;
  line-height: 3rem;
  border-radius: 25px;
  background: ${mainColor};
  color: #fff;
  font-weight: bold;
  background: no-repeat,
    linear-gradient(
      135deg,
      ${mainColor} 0%,
      ${mainColor} 50%,
      #f25e54 50%,
      #f25e54 100%
    );
  :hover,
  :active,
  :focus {
    opacity: 0.7;
    background: ${mainColor};
    color: #fff;
  }
`;

export const ModalCloseButtonStyle = css`
  position: absolute;
  top: -2rem;
  right: 0;
  background: none;
  border: none;
  width: 18px;
  height: 18px;
  z-index: 100;
  margin: 0;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 0;
    width: 18px;
    height: 2px;
    background-color: #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export const textOverflowStyle = css`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const textOverflowMultiline = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* このスタイルを適用している箇所で省略する行を指定する */
  /* -webkit-line-clamp: 3; */
`;

export const materialUiTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      // Ripple（押下したときの波紋）を不可にする
      disableRipple: true
    }
  },
  palette: {
    primary: {
      light: '#ff9f91',
      main: mainColor,
      dark: '#c23b39',
      contrastText: textColor
    }
  }
});

export const tabsStyle = css`
  border-bottom: 1px solid ${borderColor};
`;

export const tabStyle = css`
  font-size: ${headingFontSize.medium};
  font-weight: bold;
  color: ${subTextColor};
`;

export const triangleStyle = css`
  background: linear-gradient(
    to bottom right,
    transparent 0%,
    transparent 50%,
    #fce2e2 50%,
    #fce2e2 100%
  );
`;

export const chevronStyle = css`
  content: '';
  display: inline-block;
  position: relative;
  left: 0;
  top: 0.5rem;
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #fff;
  vertical-align: top;
  transform: rotate(135deg); /* chevron bottom */
`;
